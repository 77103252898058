import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { NextConfig } from 'src/app/app-config';
import { StorageService } from 'src/app/services/storage.service';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss'],
})
/**
 * Export Class
 */
export class AdminComponent implements OnInit {
  public nextConfig: any;
  public navCollapsed: boolean;
  public navCollapsedMob: boolean;
  public windowWidth: number;
  regulated: boolean = false

  /**
   * Constructor
   */
  constructor(
    private location: Location,
    private storageService: StorageService
  ) {
    this.nextConfig = NextConfig.config;
    let currentURL = this.location.path();
    const baseHerf = this.location['_baseHref'];
    if (baseHerf) {
      currentURL = baseHerf + this.location.path();
    }

    this.windowWidth = window.innerWidth;

    if ((currentURL === baseHerf + '/layout/collapse-menu' ||
      currentURL === baseHerf + '/layout/box') &&
      (this.windowWidth >= 992 && this.windowWidth <= 1024)) {
      this.nextConfig.collapseMenu = true;
    }

    this.navCollapsed = (this.windowWidth >= 992) ? this.nextConfig.collapseMenu : false;
    this.navCollapsedMob = false;
  }

  /**
   * Initial Loader
   */
  ngOnInit() {
    this.regulated = JSON.parse(this.storageService.getItem('regulated'));

    if (this.regulated != false) {
      this.storageService.setItem('regulated', true);
      this.regulated = JSON.parse(this.storageService.getItem('regulated'));
    }
    if (this.windowWidth < 992) {
      this.nextConfig.layout = 'vertical';
      setTimeout(() => {
        document.querySelector('.pcoded-navbar').classList.add('menupos-static');
        (document.querySelector('#nav-ps-next') as HTMLElement).style.maxHeight = '100%'; // 100% amit
      }, 500);
    }
  }

  /**
   * Nav Mob Click
   */
  navMobClick() {
    if (this.windowWidth < 992) {
      if (this.navCollapsedMob && !(document.querySelector('app-navigation.pcoded-navbar').classList.contains('mob-open'))) {
        this.navCollapsedMob = !this.navCollapsedMob;
        setTimeout(() => {
          this.navCollapsedMob = !this.navCollapsedMob;
        }, 100);
      } else {
        this.navCollapsedMob = !this.navCollapsedMob;
      }
    }
  }
}
