<div class="sidemenu">
    <ul class="sidemenu-view">
        <ng-container *ngFor="let nav of navData">
            <li class="navitems" *ngIf="nav?.regulated">
                <a class="nav-link" [routerLink]="[nav.route]" routerLinkActive="active">
                    <div class="tooltipsview"><span class="nav_name">{{nav.title}} </span></div>
                </a>
            </li>
        </ng-container>
    </ul>
</div>
<!-- 64 lines reduced to 11 lines -->