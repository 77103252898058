
export const navItems = [
    {
        title: 'Partner Management.',
        route: '/partner-management',
        regulated: true
    },
    {
        title: 'Fees Management.',
        route: '/fees-management',
        regulated: true
    },
    {
        title: 'Partner Fees Management.',
        route: '/partner-fees-management',
        regulated: true
    },
    {
        title: 'Whitelist Management.',
        route: '/whitelist-management',
        regulated: true
    },
    {
        title: 'Sales Royalty Splitter.',
        route: '/sales-royalty-splitter',
        regulated: true
    },
    {
        title: 'NFT Trait.',
        route: '/nft-trait',
        regulated: true
    },
    {
        title: 'Collections Management.',
        route: '/collections-management',
        regulated: true
    },
    {
        title: 'Borrow and Lend.',
        route: '/borrow-and-lend',
        regulated: true
    },
    {
        title: 'Currency Management.',
        route: '/currency-management',
        regulated: true
    },
    {
        title: 'Manage Document.',
        route: '/manage-document',
        regulated: true
    },
    {
        title: 'Faq Management.',
        route: '/faq',
        regulated: true
    },
    {
        title: 'Kyc Management.',
        route: '/kyc-management',
        regulated: structuredClone(localStorage.getItem('regulated')) === 'true'
    },
    {
        title: 'Website Visitors.',
        route: '/website-visitors',
        regulated: true
    },
    {
        title: 'Settings.',
        route: '/settings',
        regulated: true
    }
]