import { Component } from '@angular/core';
import { navItems } from './nav-data';

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.scss'],

})
export class SidebarComponent {
    navData = navItems;
}
