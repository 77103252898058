import { Component, OnInit, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Idle } from '@ng-idle/core';
import { IdleService } from './core/services/idle.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
/**
 * App Component
 */
export class AppComponent implements OnInit {
  idleWarningTime = 0;
  confirmationData = '';
  @ViewChild('sessionExpiryModal') sessionExpiryModal: { show: () => void; hide: () => void; };

  /**
   *
   * constructor
   */
  constructor(
    private router: Router,
    private idle: Idle,
    private idleService: IdleService,
  ) {
    this.idle.onIdleStart.subscribe(() => {
      this.sessionExpiryModal?.show();
    });
  }
  /**
 * loaded initially
 */
  ngOnInit() {
    this.idleService.reset();
    this.idle.onTimeoutWarning.subscribe((countdown) => {
      this.idleWarningTime = countdown;
      this.confirmationData =
        `Your session is going to expire in ${this.idleWarningTime} ${this.idleWarningTime > 1 ? 'seconds' : 'second'}.`
    })
    this.idle.onIdleEnd.subscribe(() => {
      this.sessionExpiryModal.hide()
    });

    /** called after the inactive timeout */
    this.idle.onTimeout.subscribe(() => {
      this.sessionExpiryModal.hide()
      this.router.navigate(['/login']);

    });
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
  }
}
